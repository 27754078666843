import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Players() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayersWithPrices = async () => {
      try {
        const response = await axios.get('http://localhost:5001/api/players');
        const playersData = response.data.items || [];
        
        const playersWithPrices = await Promise.all(
          playersData.map(async (player) => {
            try {
              const playerName = `${player.firstName}_${player.lastName}`;
              console.log('Fetching price for:', playerName, player.overallRating);
              
              const priceResponse = await axios.get(
                `http://localhost:5001/api/player-price/${playerName}/${player.overallRating}`
              );
              return {
                ...player,
                marketPrice: priceResponse.data.currentPrice
              };
            } catch (error) {
              console.log(`Could not fetch price for ${player.firstName} ${player.lastName}:`, error);
              return {
                ...player,
                marketPrice: 'Price not available'
              };
            }
          })
        );
        
        setPlayers(playersWithPrices);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching players:', err);
        setError('Could not fetch players');
        setLoading(false);
      }
    };

    fetchPlayersWithPrices();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen bg-[#1a1b2e]">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center min-h-screen bg-[#1a1b2e] text-white">
      {error}
    </div>
  );

  return (
    <div className="min-h-screen bg-[#1a1b2e] py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-6 text-white">Top Players in EA FC 25</h1>
        
        {/* Search and Filter Section */}
        <div className="mb-8 flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Search players..."
            className="bg-[#2A2B3F] text-white px-4 py-2 rounded-lg flex-grow"
          />
          <select className="bg-[#2A2B3F] text-white px-4 py-2 rounded-lg">
            <option value="all">All Positions</option>
            <option value="forward">Forwards</option>
            <option value="midfielder">Midfielders</option>
            <option value="defender">Defenders</option>
            <option value="goalkeeper">Goalkeepers</option>
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {players.map(player => (
            <Link to={`/players/${player.id}`} key={player.id}>
              <div className="bg-[#2A2B3F] rounded-lg p-4 hover:bg-[#3A3B4F] transition-colors">
                <div className="flex items-center space-x-4">
                  <img 
                    src={player.avatarUrl} 
                    alt={`${player.firstName} ${player.lastName}`}
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                  <div>
                    <h2 className="text-xl font-semibold text-white">
                      {player.commonName || `${player.firstName} ${player.lastName}`}
                    </h2>
                    <div className="text-blue-400 font-semibold text-lg">
                      {player.overallRating}
                    </div>
                    <p className="text-gray-400">{player.position.label}</p>
                    <p className="text-gray-400">{player.team.label}</p>
                  </div>
                </div>
                
                {/* Player Stats */}
                <div className="mt-4 grid grid-cols-3 gap-2 text-sm">
                  <div>
                    <div className="text-gray-400">Pace</div>
                    <div className="text-white">{player.stats?.pac?.value || '-'}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">Shooting</div>
                    <div className="text-white">{player.stats?.sho?.value || '-'}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">Passing</div>
                    <div className="text-white">{player.stats?.pas?.value || '-'}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">Dribbling</div>
                    <div className="text-white">{player.stats?.dri?.value || '-'}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">Defending</div>
                    <div className="text-white">{player.stats?.def?.value || '-'}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">Physical</div>
                    <div className="text-white">{player.stats?.phy?.value || '-'}</div>
                  </div>
                </div>

                {/* Add price section */}
                <div className="mt-2 text-sm">
                  <div className="text-gray-400">Market Price</div>
                  <div className="text-white font-bold">
                    {player.marketPrice ? 
                      typeof player.marketPrice === 'string' ? 
                        player.marketPrice : 
                        `${player.marketPrice.toLocaleString()} coins` 
                      : 'Loading...'}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Players;