import { useState } from 'react';
import { Link } from 'react-router-dom';

function DemoNavbar() {
  // Lägg till state för notifikationer och dropdown
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: 'alert',
      icon: '🔥',
      message: 'High volume of special cards being listed - Good time to snipe',
      timestamp: 'Just now'
    },
    {
      id: 2,
      type: 'info',
      icon: '📊',
      message: 'Market is recovering from rewards - Hold your investments',
      timestamp: '5m ago'
    },
    {
      id: 3,
      type: 'tip',
      icon: '💡',
      message: 'New SBC released - Fodder prices expected to rise',
      timestamp: '10m ago'
    }
  ]);

  return (
    <nav className="fixed w-full bg-black/40 backdrop-blur-sm z-50 border-b border-gray-800">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <span className="text-2xl font-bold">
              <span className="text-white">AI</span>
              <span className="text-blue-500">Fut</span>
              <span className="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Trader</span>
            </span>
          </Link>

          {/* Center Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button className="text-gray-300 hover:text-white transition-colors">
              Dashboard
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Trading Bot
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Analytics
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Settings
            </button>
          </div>

          {/* Right Side */}
          <div className="flex items-center space-x-4">
            {/* Notifications */}
            <div className="relative">
              <button 
                onClick={() => setShowNotifications(!showNotifications)}
                className="relative text-gray-300 hover:text-white"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  {notifications.length}
                </span>
              </button>

              {/* Uppdaterad mobilanpassad dropdown */}
              {showNotifications && (
                <div className="absolute right-0 mt-2 w-screen md:w-96 max-w-[calc(100vw-2rem)] bg-gray-900 rounded-lg shadow-lg border border-gray-800">
                  <div className="p-3 md:p-4 border-b border-gray-800">
                    <div className="flex justify-between items-center">
                      <h3 className="text-white font-semibold text-sm md:text-base">Notifications</h3>
                      <span className="text-xs md:text-sm text-blue-400">{notifications.length} new</span>
                    </div>
                  </div>
                  <div className="max-h-[60vh] md:max-h-96 overflow-y-auto">
                    {notifications.map(notification => (
                      <div 
                        key={notification.id}
                        className={`p-3 md:p-4 border-b border-gray-800 hover:bg-gray-800/50 transition-colors ${
                          notification.type === 'alert' 
                            ? 'border-l-4 border-l-red-500' 
                            : notification.type === 'info'
                            ? 'border-l-4 border-l-blue-500'
                            : 'border-l-4 border-l-green-500'
                        }`}
                      >
                        <div className="flex items-start space-x-3">
                          <span className="text-lg md:text-xl mt-1">{notification.icon}</span>
                          <div className="flex-1">
                            <p className="text-gray-300 text-sm md:text-base">{notification.message}</p>
                            <p className="text-xs md:text-sm text-gray-500 mt-1">{notification.timestamp}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* User Menu */}
            <div className="flex items-center space-x-3">
              <div className="text-right">
                <p className="text-sm text-white">Demo Account</p>
                <p className="text-xs text-gray-400">2.4M Coins</p>
              </div>
              <button className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center">
                <span className="text-sm font-bold">D</span>
              </button>
            </div>

            {/* Exit Demo Button */}
            <Link 
              to="/" 
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-sm transition-colors"
            >
              Exit Demo
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default DemoNavbar;
