import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import UserNavbar from './UserNavbar';

// Kopiera över alla styles från Demo.js
const twitterTimelineStyles = `
  .twitter-timeline-wrapper {
    min-height: 600px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .twitter-timeline {
    background-color: transparent !important;
  }
`;

function Dashboard() {
  console.log("Dashboard component mounting");
  const { user, loading } = useAuth();
  
  // Flytta ALLA useState hooks hit till toppen
  const [contentLoading, setContentLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('lazy-list');
  const [coins, setCoins] = useState('');
  const [platform, setPlatform] = useState('');
  const [strategy, setStrategy] = useState(null);
  const [players, setPlayers] = useState({ headers: [], items: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [massBidData, setMassBidData] = useState({ massBidItems: [] });
  const [joinStatus, setJoinStatus] = useState('');
  const [isJoining, setIsJoining] = useState(false);
  
  const playersPerPage = 15;

  console.log("Auth state:", { user, loading });

  // Lägg till en useEffect för att spåra renderingar
  useEffect(() => {
    console.log("Dashboard mounted");
    return () => console.log("Dashboard unmounting");
  }, []);

  console.log("User data:", user);

  // Uppdaterade menuItems med användarinfo
  const menuItems = [
    { id: 'account', title: `Account: ${user?.username || 'Loading...'}`, icon: '👤' },
    { id: 'lazy-list', title: 'Lazy List for Profit', icon: '📈' },
    { id: 'mass-bid', title: 'Mass Bid Strategy', icon: '🎯' },
    { id: 'trading-strategy', title: 'Trading Strategy', icon: '💰' },
    { id: 'price-fixing', title: 'Price Fixing Detection', icon: '🔍' },
    { id: 'auto-snipe', title: 'Auto Snipe System', icon: '⚡' },
    { id: 'market-analysis', title: 'Market Analysis', icon: '📊' },
    { id: 'extensions', title: 'Extensions', icon: '🔌' },
    { id: 'leaks', title: 'FUT Leaks', icon: '🔥' },
    { id: 'settings', title: 'Settings', icon: '⚙️' },
    { id: 'logout', title: 'Logout', icon: '🚪' },
  ];

  // Definiera fetchPlayers som en funktion
  const fetchPlayers = async () => {
    try {
      const response = await fetch('/static/data/futgg_players_latest.json');
      const data = await response.json();
      setPlayers(data);
    } catch (error) {
      console.error('Error fetching players:', error);
      const localData = localStorage.getItem('demo_players');
      if (localData) {
        setPlayers(JSON.parse(localData));
      }
    }
  };

  // Definiera fetchUserData som en funktion
  const fetchUserData = async () => {
    try {
      const response = await fetch('https://aifuttrader.com/api/get_user_data.php', {
        credentials: 'include'
      });
      const data = await response.json();
      
      if (data.success) {
        setCoins(data.data.coins);
        setPlatform(data.data.platform);
        // Uppdatera andra states med användardata
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  // Uppdatera useEffect för lazy-list
  useEffect(() => {
    if (activeTab === 'lazy-list') {
      fetchPlayers();
    }
  }, [activeTab]);

  // Uppdatera useEffect för användardata
  useEffect(() => {
    fetchUserData();
  }, []);

  // Uppdatera useEffect för initial data
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setContentLoading(true);
        await fetchUserData();
        if (activeTab === 'lazy-list') {
          await fetchPlayers();
        }
      } catch (error) {
        console.error('Failed to load initial data:', error);
      } finally {
        setContentLoading(false);
      }
    };

    loadInitialData();
  }, []);

  if (loading || contentLoading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  // Implementera alla render-funktioner
  const renderLazyList = () => {
    console.log("renderLazyList called, players:", players);
    const items = Array.isArray(players.items) ? players.items : [];
    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = items.slice(indexOfFirstPlayer, indexOfLastPlayer);
    const totalPages = Math.ceil(items.length / playersPerPage);

    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Lazy List for Profit</h3>
        
        {/* Filter Controls */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder="Coins available..."
            value={coins}
            onChange={(e) => setCoins(e.target.value)}
            className="bg-gray-800 text-white rounded px-4 py-2"
          />
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            className="bg-gray-800 text-white rounded px-4 py-2"
          >
            <option value="">Select Platform</option>
            <option value="ps">PlayStation</option>
            <option value="xbox">Xbox</option>
            <option value="pc">PC</option>
          </select>
          <button className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600">
            Apply Filters
          </button>
        </div>

        {/* Players Table */}
        <div className="overflow-x-auto">
          <table className="w-full text-left text-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                {players.headers.map((header, index) => (
                  <th key={index} className="px-4 py-2">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentPlayers.map((player, index) => (
                <tr key={index} className="border-b border-gray-800 hover:bg-gray-800/50">
                  {Object.values(player).map((value, i) => (
                    <td key={i} className="px-4 py-2">{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === i + 1
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderMassBid = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Mass Bid Strategy</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {massBidData.massBidItems.map((item, index) => (
            <div key={index} className="bg-gray-800/50 rounded-lg p-4 space-y-4">
              <h4 className="text-lg font-semibold text-white">{item.title}</h4>
              <p className="text-gray-300">{item.description}</p>
              <div className="flex justify-between text-sm">
                <span className="text-green-400">Profit: {item.profit}</span>
                <span className="text-blue-400">Success: {item.success}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderLeaks = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">FUT Leaks</h3>
        <div className="twitter-timeline-wrapper">
          <a 
            className="twitter-timeline" 
            data-theme="dark" 
            href="https://twitter.com/FutSheriff"
          >
            Loading tweets...
          </a>
        </div>
      </div>
    );
  };

  const renderTradingStrategy = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Trading Strategy</h3>
        {/* Trading strategy content */}
      </div>
    );
  };

  const renderExtensions = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Extensions</h3>
        {/* Extensions content */}
      </div>
    );
  };

  const handleJoinWaitlist = async () => {
    try {
      setIsJoining(true);
      const response = await fetch('https://aifuttrader.com/api/join_waitlist.php', {
        method: 'POST',
        credentials: 'include'
      });
      
      const data = await response.json();
      
      if (data.success) {
        setJoinStatus('success');
      } else {
        setJoinStatus('already');
      }
    } catch (error) {
      console.error('Failed to join waitlist:', error);
      setJoinStatus('error');
    } finally {
      setIsJoining(false);
    }
  };

  const renderComingSoon = () => {
    return (
      <div className="space-y-8 text-center">
        <h2 className="text-3xl font-bold text-white">Coming Soon - Premium Features</h2>
        
        <div className="max-w-2xl mx-auto bg-gray-800/50 rounded-lg p-8 space-y-6">
          <p className="text-xl text-blue-400">
            Premium Access - $20/month
          </p>
          
          <div className="space-y-4 text-gray-300">
            <p className="text-lg">
              Get exclusive access to our advanced FIFA trading tools:
            </p>
            
            <ul className="space-y-3 text-left list-none">
              <li className="flex items-center space-x-3">
                <span className="text-green-400 text-xl">✓</span>
                <span>Advanced Sniping Extension - Snipe players directly from the web app</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="text-green-400 text-xl">✓</span>
                <span>Real-time Price Analysis - Integrated directly in EA's web interface</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="text-green-400 text-xl">✓</span>
                <span>AI-Powered Market Predictions - Stay ahead of market trends</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="text-green-400 text-xl">✓</span>
                <span>Premium Trading Strategies - Curated by expert traders</span>
              </li>
            </ul>
          </div>

          <div className="mt-8 space-y-4">
            <p className="text-yellow-400">
              Due to high demand, we're currently operating with a waiting list
            </p>
            
            {joinStatus === 'success' ? (
              <div className="text-green-400">
                You've been added to the waiting list! We'll notify you when we launch.
              </div>
            ) : joinStatus === 'already' ? (
              <div className="text-blue-400">
                You're already on our waiting list. We'll notify you when we launch!
              </div>
            ) : joinStatus === 'error' ? (
              <div className="text-red-400">
                Something went wrong. Please try again later.
              </div>
            ) : (
              <button 
                onClick={handleJoinWaitlist}
                disabled={isJoining}
                className={`bg-blue-500 text-white px-8 py-3 rounded-lg transition-colors
                  ${isJoining ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
              >
                {isJoining ? 'Joining...' : 'Join Waiting List'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Lägg till renderContent funktion
  const renderContent = () => {
    console.log("renderContent called, activeTab:", activeTab);
    try {
      switch (activeTab) {
        case 'lazy-list':
        case 'mass-bid':
        case 'leaks':
        case 'extensions':
        case 'trading-strategy':
        case 'price-fixing':
        case 'auto-snipe':
        case 'market-analysis':
          return renderComingSoon();
        case 'account':
          return <div className="text-white">Account settings coming soon...</div>;
        case 'settings':
          return <div className="text-white">Settings coming soon...</div>;
        case 'logout':
          handleLogout();
          return null;
        default:
          return renderComingSoon();
      }
    } catch (error) {
      console.error("Error in renderContent:", error);
      return <div className="text-white">Error loading content</div>;
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://aifuttrader.com/api/logout.php', {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {console.log("Dashboard rendering")}
      <UserNavbar />
      <div className="pt-16">
        {/* Top Stats Bar */}
        <div className="bg-black/40 backdrop-blur-sm border-b border-gray-800">
          <div className="container mx-auto px-4 py-4">
            <div className="flex justify-between items-center">
              {/* User Info */}
              <div className="flex items-center space-x-4">
                <span className="text-white font-semibold">Welcome, {user?.username}</span>
              </div>
              
              {/* Stats */}
              <div className="grid grid-cols-3 gap-4 flex-1">
                <div className="text-center">
                  <p className="text-green-500">Daily Profit</p>
                  <p className="text-2xl text-white">+2.4M</p>
                </div>
                <div className="text-center">
                  <p className="text-blue-500">Active Signals</p>
                  <p className="text-2xl text-white">14</p>
                </div>
                <div className="text-center">
                  <p className="text-purple-500">Success Rate</p>
                  <p className="text-2xl text-white">94.6%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar */}
          <div className="md:w-64 space-y-2">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`w-full text-left px-4 py-3 rounded transition-colors flex items-center space-x-3
                  ${activeTab === item.id 
                    ? 'bg-blue-500 text-white' 
                    : 'text-gray-400 hover:bg-blue-500/10 hover:text-white'}`}
              >
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            ))}
          </div>

          {/* Content Area */}
          <div className="flex-1 min-h-[600px] bg-black/40 backdrop-blur-sm rounded-lg p-6">
            {console.log("Rendering content...")}
            {renderContent() || <div className="text-white">No content available</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;