function Pricing() {
    return (
      <div className="bg-black min-h-screen py-12 md:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center text-white mb-4">
            Simple, Transparent Pricing
          </h2>
          <p className="text-gray-400 text-center mb-12 max-w-2xl mx-auto">
            Get access to our powerful AI trading tools and join a community of successful traders
          </p>
  
          {/* Pricing Card */}
          <div className="max-w-md mx-auto bg-gray-900 rounded-lg overflow-hidden">
            <div className="bg-blue-500 p-8">
              <h3 className="text-2xl font-bold text-white mb-2">Premium Access</h3>
              <div className="flex items-center justify-center">
                <span className="text-5xl font-bold text-white">$25</span>
                <span className="text-white ml-2">/month</span>
              </div>
            </div>
  
            <div className="p-6">
              <ul className="space-y-4">
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Access to AI Trading Predictions
                </li>
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Real-time Market Analysis
                </li>
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Private Discord Community Access
                </li>
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  24/7 Trading Signals
                </li>
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Weekly Market Reports
                </li>
                <li className="flex items-center text-gray-300">
                  <svg className="w-5 h-5 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Priority Support
                </li>
              </ul>
  
              <a 
                href="https://www.patreon.com/c/aifuttrader/membership"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-blue-500 hover:bg-blue-600 text-white text-center py-4 rounded-lg mt-8 transition-colors font-semibold"
              >
                Join Patreon
              </a>
  
              <p className="text-gray-500 text-sm text-center mt-4">
                No long-term contracts. Cancel anytime.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Pricing;