import { useState } from 'react';

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await fetch('https://aifuttrader.com/api/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Login failed');
            }

            // Visa success meddelande
            setSuccess('Login successful! Redirecting to dashboard...');
            
            // Vänta 1.5 sekunder innan redirect
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 1500);

        } catch (err) {
            setError(err.message);
            console.error('Login error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-black pt-16 flex items-center justify-center">
            <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
                <h2 className="text-2xl font-bold text-white mb-6 text-center">Login to AIFutTrading</h2>
                
                {error && (
                    <div className="mb-4 p-3 bg-red-500 text-white rounded">
                        {error}
                    </div>
                )}
                
                {success && (
                    <div className="mb-4 p-3 bg-green-500 text-white rounded">
                        {success}
                    </div>
                )}
                
                <form className="space-y-4" onSubmit={handleSubmit}>
                    {/* Email */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    
                    {/* Password */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    
                    {/* Login Button */}
                    <button 
                        type="submit"
                        disabled={loading}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors disabled:opacity-50"
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
                
                {/* Forgot Password */}
                <div className="mt-4 text-center">
                    <a href="/forgot-password" className="text-blue-500 hover:text-blue-400">
                        Forgot your password?
                    </a>
                </div>
                
                {/* Register Link */}
                <div className="mt-6 text-center text-gray-400">
                    Don't have an account?{' '}
                    <a href="/register" className="text-blue-500 hover:text-blue-400">
                        Register here
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Login;
