import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function UserNavbar() {
  console.log("UserNavbar rendering");
  const { user } = useAuth();
  console.log("UserNavbar user:", user);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: 'alert',
      icon: '🔥',
      message: 'High volume of special cards being listed - Good time to snipe',
      timestamp: 'Just now'
    },
    {
      id: 2,
      type: 'info',
      icon: '📊',
      message: 'Market is recovering from rewards - Hold your investments',
      timestamp: '5m ago'
    },
    {
      id: 3,
      type: 'tip',
      icon: '💡',
      message: 'New SBC released - Fodder prices expected to rise',
      timestamp: '10m ago'
    }
  ]);

  const handleLogout = async () => {
    try {
      const response = await fetch('https://aifuttrader.com/api/logout.php', {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="fixed w-full bg-black/40 backdrop-blur-sm z-50 border-b border-gray-800">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/dashboard" className="flex items-center space-x-2">
            <span className="text-2xl font-bold">
              <span className="text-white">AI</span>
              <span className="text-blue-500">Fut</span>
              <span className="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Trader</span>
            </span>
          </Link>

          {/* Center Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button className="text-gray-300 hover:text-white transition-colors">
              Dashboard
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Trading Bot
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Analytics
            </button>
            <button className="text-gray-300 hover:text-white transition-colors">
              Settings
            </button>
          </div>

          {/* Right Side */}
          <div className="flex items-center space-x-4">
            {/* Notifications */}
            <div className="relative">
              <button 
                onClick={() => setShowNotifications(!showNotifications)}
                className="relative text-gray-300 hover:text-white"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  {notifications.length}
                </span>
              </button>

              {/* Notifications Dropdown */}
              {showNotifications && (
                <div className="absolute right-0 mt-2 w-screen md:w-96 max-w-[calc(100vw-2rem)] bg-gray-900 rounded-lg shadow-lg border border-gray-800">
                  {/* ... Samma notifikationsinnehåll som i DemoNavbar ... */}
                </div>
              )}
            </div>

            {/* User Menu */}
            <div className="flex items-center space-x-3">
              <div className="text-right">
                <p className="text-sm text-white">{user?.username || 'Loading...'}</p>
                <p className="text-xs text-gray-400">Premium Member</p>
              </div>
              <button className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center">
                <span className="text-sm font-bold">{user?.username?.[0]?.toUpperCase() || 'U'}</span>
              </button>
            </div>

            {/* Logout Button */}
            <button 
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-sm transition-colors"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default UserNavbar;