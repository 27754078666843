import React from 'react';
import Navbar from './Navbar';
import Status from './Status';
import Footer from './Footer';

function StatusPage() {
  return (
    <div className="min-h-screen bg-[#1a1b2e]">
      <Navbar />
      <Status />
      <Footer />
    </div>
  );
}

export default StatusPage; 