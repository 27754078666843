import { createContext, useContext, useState, useEffect } from 'react';

// Skapa context
const AuthContext = createContext();

// Custom hook för att använda auth context
export function useAuth() {
  return useContext(AuthContext);
}

// Auth provider component
export function AuthProvider({ children }) {
  console.log("AuthProvider mounting");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("AuthProvider useEffect running");
    const checkAuth = async () => {
      console.log("Checking auth...");
      try {
        const response = await fetch('https://aifuttrader.com/api/check_auth.php', {
          credentials: 'include'
        });
        console.log("Auth response received");
        const data = await response.json();
        console.log("Auth data:", data);
        
        if (data.authenticated) {
          console.log("User is authenticated");
          setUser(data.user);
        } else {
          console.log("User is not authenticated");
          setUser(null);
        }
      } catch (error) {
        console.error("Auth check error:", error);
        setUser(null);
      } finally {
        console.log("Setting loading to false");
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  console.log("AuthProvider rendering", { user, loading });
  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;