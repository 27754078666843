import React from 'react';

function Status() {
  return (
    <div className="container mx-auto px-4 py-16 mt-16">
      <div className="max-w-3xl mx-auto">
        {/* Status Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">System Status</h1>
          <p className="text-gray-400">Current status of our services</p>
        </div>

        {/* Status Cards */}
        <div className="space-y-6">
          {/* AI Bot Status */}
          <div className="bg-black/40 backdrop-blur-sm rounded-lg p-6 border border-green-500/20">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">AI Trading Bot</h2>
              <span className="px-3 py-1 bg-green-500/20 text-green-400 rounded-full text-sm">
                Operational
              </span>
            </div>
            <p className="text-gray-400">
              Our AI trading bot is fully operational and actively providing trading signals to our Patreon members.
            </p>
          </div>

          {/* Website Development Status */}
          <div className="bg-black/40 backdrop-blur-sm rounded-lg p-6 border border-blue-500/20">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">Website Development</h2>
              <span className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm">
                In Progress
              </span>
            </div>
            <p className="text-gray-400">
              We are actively developing additional features and improvements for the website. Stay tuned for updates!
            </p>
          </div>

          {/* Access Information */}
          <div className="bg-black/40 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">How to Get Access</h2>
              <a 
                href="https://patreon.com/aifuttrader"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded transition-colors text-sm"
              >
                Join Patreon
              </a>
            </div>
            <p className="text-gray-400">
              Get immediate access to our AI trading bot through Discord by joining our Patreon community. 
              Receive real-time trading signals and market analysis.
            </p>
          </div>
        </div>

        {/* Additional Information */}
        <div className="mt-12 text-center">
          <p className="text-gray-400 mb-6">
            For the latest updates and announcements, follow us on our social media channels.
          </p>
          <div className="flex justify-center space-x-4">
            <a 
              href="https://patreon.com/aifuttrader"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 transition-colors"
            >
              Patreon
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status;