import { Link } from 'react-router-dom';
import { useState } from 'react';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      {/* Navbar - uppdatera positioning */}
      <nav className="fixed w-full bg-black/40 backdrop-blur-sm z-40 top-0">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2">
              <span className="text-2xl font-bold flex items-center">
                <span className="text-white">AI</span>
                <span className="text-blue-500">Fut</span>
                <span className="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Trader</span>
                <span className="ml-2 text-xs bg-blue-500/20 text-blue-400 px-2 py-0.5 rounded-full border border-blue-500/20">BETA</span>
              </span>
            </Link>
            
            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/" className="text-gray-300 hover:text-white transition-colors">
                Home
              </Link>

              <Link to="/how-it-works" className="text-gray-300 hover:text-white transition-colors">
                How It Works
              </Link>

              <Link to="/status" className="text-gray-300 hover:text-white transition-colors">
                Status
              </Link>

              {/* Products Dropdown */}
              <div className="relative group">
                <button 
                  className="text-gray-300 hover:text-white transition-colors flex items-center"
                  onClick={() => setIsProductsOpen(!isProductsOpen)}
                >
                  Products
                  <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div className="absolute left-0 mt-2 w-48 bg-black/95 backdrop-blur-sm rounded-lg shadow-lg py-2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
                  {/* Software Products */}
                  <div className="px-4 py-2 text-xs text-gray-500 font-semibold">SOFTWARE</div>
                  <Link to="/ai-software" className="block px-4 py-2 text-gray-300 hover:text-white">
                    AI Trading Software
                  </Link>
                  <Link to="/extensions" className="block px-4 py-2 text-gray-300 hover:text-white">
                    Browser Extensions
                  </Link>
                  
                  {/* Divider */}
                  <div className="my-2 border-t border-gray-800"></div>
                  
                  {/* Information */}
                  <div className="px-4 py-2 text-xs text-gray-500 font-semibold">INFORMATION</div>
                  <button onClick={() => scrollToSection('features')} className="block px-4 py-2 text-gray-300 hover:text-white w-full text-left">
                    Features
                  </button>
                  <button onClick={() => scrollToSection('pricing')} className="block px-4 py-2 text-gray-300 hover:text-white w-full text-left">
                    Pricing
                  </button>
                </div>
              </div>

              {/* Resources Dropdown */}
              <div className="relative group">
                <button 
                  className="text-gray-300 hover:text-white transition-colors flex items-center"
                  onClick={() => setIsResourcesOpen(!isResourcesOpen)}
                >
                  Resources
                  <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div className="absolute left-0 mt-2 w-48 bg-black/95 backdrop-blur-sm rounded-lg shadow-lg py-2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
                  <Link to="/content-calendar" className="block px-4 py-2 text-gray-300 hover:text-white">
                    Content Calendar
                  </Link>
                  <Link to="/market-updates" className="block px-4 py-2 text-gray-300 hover:text-white">
                    Market Updates
                  </Link>
                  <button onClick={() => scrollToSection('testimonials')} className="block px-4 py-2 text-gray-300 hover:text-white w-full text-left">
                    Testimonials
                  </button>
                  <button onClick={() => scrollToSection('faq')} className="block px-4 py-2 text-gray-300 hover:text-white w-full text-left">
                    FAQ
                  </button>
                </div>
              </div>

              {/* Account & Discord */}
              <div className="flex items-center">
                <a 
                  href="https://www.patreon.com/c/aifuttrader/membership" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors"
                >
                  Get AI Bot Access
                </a>
              </div>
            </div>

            {/* Mobile Menu Toggle */}
            <button 
              className="md:hidden text-white"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                {isMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="md:hidden bg-black/95 backdrop-blur-sm mt-2 rounded-lg p-4">
              <div className="flex flex-col space-y-4">
                <Link 
                  to="/" 
                  className="text-gray-300 hover:text-white transition-colors text-left" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </Link>

                <Link 
                  to="/how-it-works" 
                  className="text-gray-300 hover:text-white transition-colors text-left"
                  onClick={() => setIsMenuOpen(false)}
                >
                  How It Works
                </Link>

                <Link 
                  to="/status" 
                  className="text-gray-300 hover:text-white transition-colors text-left"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Status
                </Link>

                {/* Products Section */}
                <div className="space-y-2">
                  <button 
                    onClick={() => setIsProductsOpen(!isProductsOpen)}
                    className="text-gray-300 hover:text-white transition-colors w-full text-left flex items-center justify-between"
                  >
                    Products
                    <svg className={`w-4 h-4 transform ${isProductsOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isProductsOpen && (
                    <div className="pl-4 space-y-2">
                      <button onClick={() => scrollToSection('features')} className="text-gray-300 hover:text-white transition-colors block w-full text-left">
                        Features
                      </button>
                      <button onClick={() => scrollToSection('pricing')} className="text-gray-300 hover:text-white transition-colors block w-full text-left">
                        Pricing
                      </button>
                    </div>
                  )}
                </div>

                {/* Resources Section */}
                <div className="space-y-2">
                  <button 
                    onClick={() => setIsResourcesOpen(!isResourcesOpen)}
                    className="text-gray-300 hover:text-white transition-colors w-full text-left flex items-center justify-between"
                  >
                    Resources
                    <svg className={`w-4 h-4 transform ${isResourcesOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isResourcesOpen && (
                    <div className="pl-4 space-y-2">
                      <Link to="/content-calendar" className="text-gray-300 hover:text-white transition-colors block">
                        Content Calendar
                      </Link>
                      <Link to="/market-updates" className="text-gray-300 hover:text-white transition-colors block">
                        Market Updates
                      </Link>
                      <button onClick={() => scrollToSection('testimonials')} className="text-gray-300 hover:text-white transition-colors block w-full text-left">
                        Testimonials
                      </button>
                      <button onClick={() => scrollToSection('faq')} className="text-gray-300 hover:text-white transition-colors block w-full text-left">
                        FAQ
                      </button>
                    </div>
                  )}
                </div>

                {/* Account & Discord */}
                <a 
                  href="https://www.patreon.com/c/aifuttrader/membership" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-center transition-colors"
                >
                  Get AI Bot Access
                </a>
              </div>
            </div>
          )}
        </div>
      </nav>

      {/* Uppdatera spacer */}
      <div className="h-16" />
    </>
  );
}

export default Navbar;
