import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

function Extensions() {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-black text-white">
        <div className="container mx-auto px-4 py-16">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              FIFA Trading Browser Extensions
            </h1>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Enhance your trading experience directly in the FIFA Web App
            </p>
          </div>

          {/* Extension Features */}
          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {/* Chrome Extension */}
            <div className="bg-gray-900/50 backdrop-blur-sm p-8 rounded-lg">
              <div className="flex items-center mb-6">
                <h3 className="text-2xl font-semibold">Chrome Extension</h3>
              </div>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Instant price checks while trading
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  One-click mass bidding
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Real-time profit calculations
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Market price notifications
                </li>
              </ul>
            </div>

            {/* Firefox Extension */}
            <div className="bg-gray-900/50 backdrop-blur-sm p-8 rounded-lg">
              <div className="flex items-center mb-6">
                <h3 className="text-2xl font-semibold">Firefox Extension</h3>
              </div>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Seamless Web App integration
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Advanced filter presets
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Custom price alerts
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  Trading statistics dashboard
                </li>
              </ul>
            </div>
          </div>

          {/* Coming Soon Banner */}
          <div className="bg-blue-600/20 border border-blue-500 rounded-lg p-6 mb-16">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold mb-2">Coming Soon to Edge & Safari</h3>
                <p className="text-gray-400">
                  We're working on bringing our powerful trading tools to more browsers
                </p>
              </div>
              <span className="bg-blue-500 px-4 py-2 rounded-full text-sm font-semibold">
                In Development
              </span>
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl p-8 text-center">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Get Early Access to Our Extensions
            </h2>
            <p className="text-lg mb-6">
              Join our waiting list to be notified when we launch
            </p>
            <Link
              to="/register"
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors inline-block"
            >
              Join Waiting List
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Extensions;