import React from 'react';

const marketUpdates = [
  {
    id: 1,
    title: "Market Status: Temporary Decline",
    date: "2024-10-26",
    time: "11:31",
    type: "Daily Update",
    content: "The market is experiencing a temporary decline due to the lack of popular SBCs and the launch of a new major promo. While all prices are dropping, this presents a perfect investment opportunity as the market tends to recover quickly when new SBCs are released.",
    tags: ["market-crash", "investment-opportunity"]
  },
  {
    id: 2,
    title: "91 Rodri - Golden Investment Opportunity",
    date: "2024-10-17",
    time: "10:02",
    type: "Investment Tip",
    content: "Rodri (91) is currently only 42K. Based on last year's data, where 90-rated cards rose to 60k+ in late October, we expect a significant price increase in the coming weeks. Perfect time to invest.",
    tags: ["fodder-investment", "high-rated"]
  },
  {
    id: 3,
    title: "Icon SBC Impact",
    date: "2024-10-16",
    time: "17:07",
    type: "SBC Alert",
    content: "The Icon SBC is expected to drop today. Market reaction could go either way, but the ChilledCoins list remains relatively safe. Even if prices dip, we expect a quick recovery to current levels. List your players around 18:03 CEST.",
    tags: ["icon-sbc", "market-alert"]
  },
  {
    id: 4,
    title: "Division Rivals Rewards - Bidding Strategy",
    date: "2024-10-17",
    time: "09:23",
    type: "Trading Tip",
    content: "Start bidding 70 minutes after Division Rivals rewards. Focus on 84-rated players with minimum price of 1.5k. Aim for at least 500 coins profit per player. Check current prices before starting.",
    tags: ["mass-bidding", "rewards-trading"]
  },
  {
    id: 5,
    title: "88+ Fodder Investment Opportunity",
    date: "2024-10-17",
    time: "09:38",
    type: "Investment Alert",
    content: "88+ SBC fodder cards are listed at very low prices due to not being required for the ICON SBC. Great opportunity to stock up. Example: 91-rated Rodri at only 43K. Historically, fodder prices explode by end of October.",
    tags: ["fodder-investment", "market-opportunity"]
  }
];

function MarketUpdates() {
  return (
    <div className="min-h-screen bg-black text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Market Updates</h1>
        
        <div className="space-y-6">
          {marketUpdates.map(update => (
            <div 
              key={update.id} 
              className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-colors"
            >
              <div className="flex items-center justify-between mb-4">
                <span className="px-3 py-1 bg-blue-500/20 rounded-full text-blue-400 text-sm">
                  {update.type}
                </span>
                <div className="text-gray-400 text-sm">
                  {update.date} {update.time}
                </div>
              </div>
              
              <h2 className="text-xl font-semibold mb-3">{update.title}</h2>
              <p className="text-gray-300 mb-4">{update.content}</p>
              
              <div className="flex flex-wrap gap-2">
                {update.tags.map(tag => (
                  <span 
                    key={tag} 
                    className="px-2 py-1 bg-gray-800 rounded-full text-gray-400 text-xs"
                  >
                    #{tag}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MarketUpdates;