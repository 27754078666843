import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Hero from './components/Hero';
import Features from './components/Features';
import Navbar from './components/Navbar';
import HowItWorks from './components/HowItWorks';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Pricing from './components/Pricing';
import Login from './components/Login';
import Register from './components/Register';
import Demo from './components/Demo';
import DemoNavbar from './components/DemoNavbar';
import Dashboard from './components/Dashboard';
import Admin from './components/Admin';
import MarketUpdates from './components/MarketUpdates';
import AISoftware from './components/AISoftware';
import Extensions from './components/Extensions';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import ContentCalendar from './components/ContentCalendar';
import Players from './components/Players';
import PlayerDetails from './components/PlayerDetails';
import StatusPage from './components/StatusPage';
import './App.css';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <div className="min-h-screen bg-black">
            <Routes>
              {/* Huvudsidan */}
              <Route path="/" element={
                <>
                  <Navbar />
                  <section id="home">
                    <Hero />
                  </section>
                  <section id="features">
                    <Features />
                  </section>
                  <section id="how-it-works">
                    <HowItWorks />
                  </section>
                  <section id="testimonials">
                    <Testimonials />
                  </section>
                  <section id="pricing">
                    <Pricing />
                  </section>
                  <section id="faq">
                    <FAQ />
                  </section>
                  <Footer />
                </>
              } />
              
              {/* Lägg till denna nya route för How It Works */}
              <Route path="/how-it-works" element={
                <>
                  <Navbar />
                  <HowItWorks />
                  <Footer />
                </>
              } />
              
              {/* Players route */}
              <Route path="/players" element={
                <>
                  <Navbar />
                  <div className="pt-16">
                    <Players />
                  </div>
                </>
              } />
              
              {/* Dashboard route för inloggade användare */}
              <Route path="/dashboard" element={
                <>
                  <Dashboard />
                </>
              } />
              
              {/* Demo-sidan */}
              <Route path="/demo" element={
                <>
                  <DemoNavbar />
                  <div className="pt-16">
                    <Demo />
                  </div>
                </>
              } />
              
              {/* Market Updates */}
              <Route path="/market-updates" element={
                <>
                  <Navbar />
                  <div className="pt-16">
                    <MarketUpdates />
                  </div>
                </>
              } />
              
              {/* Andra routes */}
              <Route path="/login" element={
                <>
                  <Navbar />
                  <Login />
                  <Footer />
                </>
              } />
              <Route path="/register" element={<Register />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/ai-software" element={<AISoftware />} />
              <Route path="/extensions" element={<Extensions />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/content-calendar" element={<ContentCalendar />} />
              <Route path="/players/:id" element={
                <>
                  <Navbar />
                  <div className="pt-16">
                    <PlayerDetails />
                  </div>
                </>
              } />
              <Route path="/status" element={<StatusPage />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
