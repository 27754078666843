function FAQ() {
  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          Frequently Asked Questions
        </h2>

        <div className="max-w-3xl mx-auto space-y-6">
          {/* Fråga 1 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              How does the AI trading system work?
            </h3>
            <p className="text-gray-300">
              Our AI analyzes market trends, player prices, and trading patterns in real-time to provide accurate
              trading recommendations. It processes millions of data points every hour to identify profitable trading opportunities.
            </p>
          </div>

          {/* Fråga 2 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              What is the success rate of the predictions?
            </h3>
            <p className="text-gray-300">
              Our AI trading system maintains an average success rate of 85-90% for price predictions. We continuously monitor and improve our algorithms to ensure the highest possible accuracy.
            </p>
          </div>

          {/* Fråga 3 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              How much initial capital do I need?
            </h3>
            <p className="text-gray-300">
              While you can start with any amount, we recommend having at least 100,000 coins to maximize the benefits of our trading signals. However, many users have successfully grown smaller amounts using our strategies.
            </p>
          </div>

          {/* Fråga 4 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              Is this allowed by EA's terms of service?
            </h3>
            <p className="text-gray-300">
              Yes, our service provides market analysis and trading recommendations only. We don't use any automated buying/selling tools or bots, keeping everything within EA's guidelines.
            </p>
          </div>

          {/* Fråga 5 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              How do I get started?
            </h3>
            <p className="text-gray-300">
              Simply register for an account, join our Discord community, and you'll get immediate access to our AI predictions and trading signals. We also provide comprehensive guides for beginners.
            </p>
          </div>

          {/* Fråga 6 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              What support do you offer?
            </h3>
            <p className="text-gray-300">
              We provide 24/7 support through our Discord community, where you can get help from both our team and experienced members. We also offer regular trading workshops and strategy sessions.
            </p>
          </div>

          {/* Fråga 7 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              Can I cancel my subscription anytime?
            </h3>
            <p className="text-gray-300">
              Yes, you can cancel your subscription at any time with no questions asked. We offer a 7-day money-back guarantee for new members if you're not satisfied with our service.
            </p>
          </div>

          {/* Fråga 8 */}
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-bold text-white mb-3">
              How often are trading signals sent?
            </h3>
            <p className="text-gray-300">
              Our AI system sends trading signals 24/7 whenever profitable opportunities are identified. On average, members receive 15-20 high-quality trading signals per day, with more during major market events.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
