import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

function AISoftware() {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-black text-white">
        <div className="container mx-auto px-4 py-16">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              AI-Powered FIFA Trading Software
            </h1>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Revolutionize your FIFA trading with advanced artificial intelligence and machine learning algorithms
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {/* Auto Sniper */}
            <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
              <div className="text-blue-500 text-3xl mb-4">⚡</div>
              <h3 className="text-xl font-semibold mb-3">Auto Sniper</h3>
              <p className="text-gray-400">
                Lightning-fast automated sniping with customizable filters and instant buy execution
              </p>
            </div>

            {/* Market Analysis */}
            <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
              <div className="text-blue-500 text-3xl mb-4">📊</div>
              <h3 className="text-xl font-semibold mb-3">Market Analysis</h3>
              <p className="text-gray-400">
                Real-time price tracking and market trend analysis powered by AI
              </p>
            </div>

            {/* Price Predictions */}
            <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
              <div className="text-blue-500 text-3xl mb-4">🎯</div>
              <h3 className="text-xl font-semibold mb-3">Price Predictions</h3>
              <p className="text-gray-400">
                AI-driven price predictions to help you make informed trading decisions
              </p>
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl p-8 text-center">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Ready to Transform Your Trading?
            </h2>
            <p className="text-lg mb-6">
              Join our waiting list to get early access and exclusive benefits
            </p>
            <Link
              to="/register"
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors inline-block"
            >
              Join Waiting List
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AISoftware;