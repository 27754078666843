import React, { useState, useEffect } from 'react';

function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [lazyListFile, setLazyListFile] = useState(null);
  const [massBidFile, setMassBidFile] = useState(null);
  
  const correctPassword = 'jb123';

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsLoggedIn(true);
      setError('');
    } else {
      setError('Fel lösenord');
    }
  };

  const handleFileUpload = async (fileType) => {
    const file = fileType === 'massBid' ? massBidFile : lazyListFile;
    if (file) {
      try {
        if (!file.name.endsWith('.json')) {
          setError('Only JSON files are allowed');
          return;
        }

        const content = await file.text();
        const jsonData = JSON.parse(content);
        
        // Validera struktur baserat på filtyp
        if (fileType === 'massBid') {
          if (!validateMassBidFormat(jsonData)) {
            setError('Invalid mass bid JSON structure');
            return;
          }
        } else {
          // Existing validation for lazy list
          if (!jsonData.items || !Array.isArray(jsonData.items)) {
            setError('Invalid JSON structure: missing items array');
            return;
          }
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', fileType);

        const response = await fetch('/api/upload.php', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          const result = await response.json();
          if (result.success) {
            setMessage('File has been uploaded and saved');
            setLazyListFile(null);
            setMassBidFile(null);
            // Trigga omladdning av data i Demo-komponenten
            window.dispatchEvent(new Event('playersUpdated'));
          } else {
            setError(result.error || 'Could not upload file');
          }
        } else {
          setError('Could not upload file');
        }

      } catch (err) {
        setError('File upload error: ' + err.message);
      }
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setPassword('');
    setMessage('');
    setError('');
  };

  const validateMassBidFormat = (data) => {
    return data.massBidItems && 
           Array.isArray(data.massBidItems) &&
           data.massBidItems.every(item => 
             item.player && 
             item.rating &&
             item.position &&
             item.nation &&
             item.league &&
             item.team &&
             item.rarity &&
             item.minBid
           );
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-2xl font-bold mb-8">AI FUT Trader Admin</h1>
          
          {!isLoggedIn ? (
            // Login Form
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <label className="block text-sm mb-2">Lösenord</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 rounded bg-gray-800 border border-gray-700 text-white"
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 rounded bg-blue-500 hover:bg-blue-600"
              >
                Logga in
              </button>
              {error && (
                <div className="bg-red-500/20 border border-red-500 rounded p-4">
                  {error}
                </div>
              )}
            </form>
          ) : (
            <div className="space-y-8">
              {/* Lazy List Upload Section */}
              <div className="bg-gray-800/50 rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-blue-400">
                  <span className="mr-2">📈</span>
                  Lazy List for Profit
                </h2>
                <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <label className="block text-sm mb-2">Select Lazy List JSON File</label>
                    <input
                      type="file"
                      accept=".json"
                      onChange={(e) => setLazyListFile(e.target.files[0])}
                      className="w-full px-4 py-2 rounded bg-gray-700"
                    />
                  </div>
                  <button
                    onClick={() => handleFileUpload('lazyList')}
                    className="w-full px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 transition-colors"
                    disabled={!lazyListFile}
                  >
                    Upload Lazy List
                  </button>
                </form>
              </div>

              {/* Mass Bid Upload Section */}
              <div className="bg-gray-800/50 rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-green-400">
                  <span className="mr-2">🎯</span>
                  Mass Bid Strategy
                </h2>
                <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <label className="block text-sm mb-2">Select Mass Bid JSON File</label>
                    <input
                      type="file"
                      accept=".json"
                      onChange={(e) => setMassBidFile(e.target.files[0])}
                      className="w-full px-4 py-2 rounded bg-gray-700"
                    />
                  </div>
                  <button
                    onClick={() => handleFileUpload('massBid')}
                    className="w-full px-4 py-2 rounded bg-green-500 hover:bg-green-600 transition-colors"
                    disabled={!massBidFile}
                  >
                    Upload Mass Bid Data
                  </button>
                </form>
              </div>

              {/* Status Messages */}
              {message && (
                <div className="bg-green-500/20 border border-green-500 rounded p-4">
                  {message}
                </div>
              )}

              {error && (
                <div className="bg-red-500/20 border border-red-500 rounded p-4">
                  {error}
                </div>
              )}

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 rounded bg-red-500 hover:bg-red-600 transition-colors"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
