import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-900">
      {/* Huvudinnehåll */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Kolumn 1 - Om oss */}
          <div>
            <div className="mb-4">
              <Link to="/" className="text-2xl font-bold">
                <span className="text-white">AI</span>
                <span className="text-blue-500">Fut</span>
                <span className="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">Trader</span>
              </Link>
            </div>
            <p className="text-gray-400 mb-4">
              Advanced AI trading solutions for EA FC 25
            </p>
            <div className="flex space-x-4">
              <a href="hhttps://www.patreon.com/c/aifuttrader/membership" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-blue-500">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/></svg>
              </a>
            </div>
          </div>

          {/* Kolumn 2 - Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-blue-500">Home</Link></li>
              <li><Link to="/features" className="text-gray-400 hover:text-blue-500">Features</Link></li>
              <li><Link to="/pricing" className="text-gray-400 hover:text-blue-500">Pricing</Link></li>
              <li><Link to="/about" className="text-gray-400 hover:text-blue-500">About</Link></li>
            </ul>
          </div>

          {/* Kolumn 3 - Support */}
          <div>
            <h3 className="text-white font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/faq" className="text-gray-400 hover:text-blue-500">FAQ</Link></li>
              <li><Link to="/contact" className="text-gray-400 hover:text-blue-500">Contact Us</Link></li>
              <li><Link to="/discord" className="text-gray-400 hover:text-blue-500">Discord Community</Link></li>
              <li><Link to="/guides" className="text-gray-400 hover:text-blue-500">Trading Guides</Link></li>
            </ul>
          </div>

          {/* Kolumn 4 - Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="text-gray-400 hover:text-blue-500">Privacy Policy</Link></li>
              <li><Link to="/terms" className="text-gray-400 hover:text-blue-500">Terms of Service</Link></li>
              <li><Link to="/refund" className="text-gray-400 hover:text-blue-500">Refund Policy</Link></li>
              <li><Link to="/cookies" className="text-gray-400 hover:text-blue-500">Cookie Policy</Link></li>
            </ul>
          </div>
        </div>
      </div>

      {/* Undre delen med copyright */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">
              © 2024 AIFutTrader. All rights reserved.
            </div>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link to="/privacy" className="text-gray-400 hover:text-blue-500 text-sm">Privacy</Link>
              <Link to="/terms" className="text-gray-400 hover:text-blue-500 text-sm">Terms</Link>
              <Link to="/cookies" className="text-gray-400 hover:text-blue-500 text-sm">Cookies</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
