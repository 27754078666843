// Först definierar vi FeatureCard-komponenten
function FeatureCard({ title, description }) {
  return (
    <div className="bg-gray-900 p-6 rounded-lg hover:bg-gray-800 transition-colors">
      <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  );
}

// Sedan huvudkomponenten Features
function Features() {
  return (
    <div className="bg-black text-white py-12 md:py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">
          Why Choose <span className="text-blue-500">AIFutTrading</span>
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          <FeatureCard 
            title="AI-Powered Analysis"
            description="Our advanced AI model analyzes market trends and player prices in real-time for maximum profit"
          />
          <FeatureCard 
            title="Community-Driven"
            description="Join our active Discord community for trading tips and discussions with fellow traders"
          />
          <FeatureCard 
            title="Proven Results"
            description="Members report significant coin growth using our AI predictions and trading signals"
          />
        </div>
      </div>
    </div>
  );
}

export default Features;
