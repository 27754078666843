function Testimonials() {
  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          What Our Users Say
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Recension 1 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "Started using AIFutTrader last month and already made over 1.5M coins. The AI predictions for icon trading have been incredibly accurate!"
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">Marcus Andersson</h4>
                <p className="text-blue-500 text-sm">Verified User</p>
              </div>
            </div>
          </div>

          {/* Recension 2 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "The community is amazing and supportive. Made 800k profit in just one weekend following the special cards predictions. Definitely worth the investment!"
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">David Martinez</h4>
                <p className="text-blue-500 text-sm">Premium Member</p>
              </div>
            </div>
          </div>

          {/* Recension 3 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "Been trading in FC for years, but never saw results like this. The AI predictions for TOTW investments are spot on. Made 3M coins in three weeks!"
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">Thomas Schmidt</h4>
                <p className="text-blue-500 text-sm">Verified User</p>
              </div>
            </div>
          </div>

          {/* Recension 4 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "The real-time market crash alerts have saved my investments multiple times. Already made back the subscription cost many times over."
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">Oliver Wilson</h4>
                <p className="text-blue-500 text-sm">Premium Member</p>
              </div>
            </div>
          </div>

          {/* Recension 5 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "Best trading tool I've used. The Discord community is super helpful and the AI predictions for promo cards are incredibly accurate. Up 4M coins since joining!"
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">Lucas van der Berg</h4>
                <p className="text-blue-500 text-sm">Verified User</p>
              </div>
            </div>
          </div>

          {/* Recension 6 */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
            <p className="text-gray-300 mb-4">
              "The market analysis tools are fantastic. Made consistent profits with hero cards trading. The AI predictions have been right 9 out of 10 times!"
            </p>
            <div className="flex items-center">
              <div>
                <h4 className="text-white font-semibold">Alessandro Romano</h4>
                <p className="text-blue-500 text-sm">Premium Member</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
