import { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function ContentCalendar() {
  const [currentMonth, setCurrentMonth] = useState('NOVEMBER 2024');
  
  // Kalenderdata för både november och december
  const calendarDataByMonth = {
    'NOVEMBER 2024': [
      { day: 27, events: [], isCurrentMonth: false },
      { day: 28, events: [], isCurrentMonth: false },
      { day: 29, events: [], isCurrentMonth: false },
      { day: 30, events: [
        { time: '5:59PM', text: 'TOTW 6 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 7 STARTS', type: 'predicted' }
      ], isCurrentMonth: false },
      { day: 31, events: [], isCurrentMonth: false },
      { day: 1, events: [
        { time: '5:59PM', text: 'TRAIL BLAZERS 1 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TRAIL BLAZERS 2 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 2, events: [], isCurrentMonth: true },
      { day: 3, events: [], isCurrentMonth: true },
      { day: 4, events: [], isCurrentMonth: true },
      { day: 5, events: [], isCurrentMonth: true },
      { day: 6, events: [
        { time: '5:59PM', text: 'TOTW 7 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 8 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true, isToday: true },
      { day: 7, events: [], isCurrentMonth: true },
      { day: 8, events: [
        { time: '5:59PM', text: 'TRAIL BLAZERS 2 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'FUT CENTURION STARTS', type: 'predicted' }
      ], isCurrentMonth: true },
      { day: 9, events: [], isCurrentMonth: true },
      { day: 10, events: [], isCurrentMonth: true },
      { day: 11, events: [], isCurrentMonth: true },
      { day: 12, events: [], isCurrentMonth: true },
      { day: 13, events: [
        { time: '5:59PM', text: 'TOTW 8 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 9 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 14, events: [], isCurrentMonth: true },
      { day: 15, events: [
        { time: '5:59PM', text: 'FUT CENTURION ENDS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 16, events: [], isCurrentMonth: true },
      { day: 17, events: [], isCurrentMonth: true },
      { day: 18, events: [], isCurrentMonth: true },
      { day: 19, events: [], isCurrentMonth: true },
      { day: 20, events: [
        { time: '5:59PM', text: 'TOTW 9 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 10 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 21, events: [], isCurrentMonth: true },
      { day: 22, events: [], isCurrentMonth: true },
      { day: 23, events: [], isCurrentMonth: true },
      { day: 24, events: [], isCurrentMonth: true },
      { day: 25, events: [], isCurrentMonth: true },
      { day: 26, events: [], isCurrentMonth: true },
      { day: 27, events: [], isCurrentMonth: true },
      { day: 28, events: [], isCurrentMonth: true },
      { day: 29, events: [], isCurrentMonth: true },
      { day: 30, events: [], isCurrentMonth: true }
    ],
    'DECEMBER 2024': [
      { day: 1, events: [], isCurrentMonth: true },
      { day: 2, events: [], isCurrentMonth: true },
      { day: 3, events: [], isCurrentMonth: true },
      { day: 4, events: [
        { time: '5:59PM', text: 'TOTW 11 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 12 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 5, events: [], isCurrentMonth: true },
      { day: 6, events: [], isCurrentMonth: true },
      { day: 7, events: [], isCurrentMonth: true },
      { day: 8, events: [], isCurrentMonth: true },
      { day: 9, events: [], isCurrentMonth: true },
      { day: 10, events: [], isCurrentMonth: true },
      { day: 11, events: [
        { time: '5:59PM', text: 'TOTW 12 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 13 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 12, events: [], isCurrentMonth: true },
      { day: 13, events: [], isCurrentMonth: true },
      { day: 14, events: [], isCurrentMonth: true },
      { day: 15, events: [], isCurrentMonth: true },
      { day: 16, events: [], isCurrentMonth: true },
      { day: 17, events: [], isCurrentMonth: true },
      { day: 18, events: [
        { time: '5:59PM', text: 'TOTW 13 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 14 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 19, events: [], isCurrentMonth: true },
      { day: 20, events: [], isCurrentMonth: true },
      { day: 21, events: [], isCurrentMonth: true },
      { day: 22, events: [], isCurrentMonth: true },
      { day: 23, events: [], isCurrentMonth: true },
      { day: 24, events: [], isCurrentMonth: true },
      { day: 25, events: [
        { time: '5:59PM', text: 'TOTW 14 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 15 STARTS', type: 'confirmed' }
      ], isCurrentMonth: true },
      { day: 26, events: [], isCurrentMonth: true },
      { day: 27, events: [], isCurrentMonth: true },
      { day: 28, events: [], isCurrentMonth: true },
      { day: 29, events: [], isCurrentMonth: true },
      { day: 30, events: [], isCurrentMonth: true },
      { day: 31, events: [], isCurrentMonth: true },
      { day: 1, events: [
        { time: '5:59PM', text: 'TOTW 15 ENDS', type: 'confirmed' },
        { time: '6PM', text: 'TOTW 16 STARTS', type: 'confirmed' }
      ], isCurrentMonth: false },
      { day: 2, events: [], isCurrentMonth: false },
      { day: 3, events: [], isCurrentMonth: false },
      { day: 4, events: [], isCurrentMonth: false }
    ]
  };

  const handlePrevMonth = () => {
    if (currentMonth === 'DECEMBER 2024') {
      setCurrentMonth('NOVEMBER 2024');
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 'NOVEMBER 2024') {
      setCurrentMonth('DECEMBER 2024');
    }
  };

  // Använd aktuell månads data
  const calendarData = calendarDataByMonth[currentMonth];

  return (
    <>
      <Navbar />
      
      <div className="min-h-screen bg-[#1a1b2e]">
        <div className="container mx-auto px-4 py-8">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-white mb-2">CONTENT CALENDAR</h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-emerald-400 rounded"></div>
                  <span className="text-white">CONFIRMED</span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-purple-400 rounded"></div>
                  <span className="text-white">PREDICTED</span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-red-400 rounded"></div>
                  <span className="text-white">HAPPENED IN EA FC24</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#2A2B3F] rounded-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl text-white font-bold">{currentMonth}</h2>
              <div className="flex space-x-4 items-center">
                <button onClick={handlePrevMonth} className="text-white hover:bg-gray-700 p-2 rounded">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                <button onClick={handleNextMonth} className="text-white hover:bg-gray-700 p-2 rounded">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
                <button className="bg-[#394254] text-white px-4 py-2 rounded">TODAY</button>
              </div>
            </div>

            <div className="grid grid-cols-7 gap-1">
              <div className="text-center text-gray-400 py-2">SUN</div>
              <div className="text-center text-gray-400 py-2">MON</div>
              <div className="text-center text-gray-400 py-2">TUE</div>
              <div className="text-center text-gray-400 py-2">WED</div>
              <div className="text-center text-gray-400 py-2">THU</div>
              <div className="text-center text-gray-400 py-2">FRI</div>
              <div className="text-center text-gray-400 py-2">SAT</div>
              
              {calendarData.map((day, index) => (
                <div 
                  key={index}
                  className={`min-h-[100px] p-2 border border-gray-700 rounded ${
                    day.isToday ? 'bg-green-900/20' : 
                    day.isCurrentMonth ? '' : 'opacity-50'
                  }`}
                >
                  <div className="text-gray-400 mb-2">{day.day}</div>
                  <div className="space-y-2">
                    {day.events.map((event, eventIndex) => (
                      <div key={eventIndex} className="text-xs">
                        <div className={`flex items-center space-x-1 ${
                          event.type === 'confirmed' ? 'text-emerald-400' :
                          event.type === 'predicted' ? 'text-purple-400' : 'text-red-400'
                        }`}>
                          <div className="w-2 h-2 rounded-full bg-current"></div>
                          <span>{event.time}</span>
                        </div>
                        <div className="text-white ml-3">{event.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContentCalendar;