import React from 'react';
import aiAnalysis from './images/ai-analysis.png';
import discordAlerts from './images/discord-alerts.png';
import profit1 from './images/profits/profit1.png';
import profit2 from './images/profits/profit2.png';
import profit3 from './images/profits/profit3.png';

function HowItWorks() {
  return (
    <div className="container mx-auto px-4 py-16 mt-16">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
          How Our AI Trading Bot Works
        </h1>
        <p className="text-xl text-gray-400 max-w-3xl mx-auto">
          Discover how our advanced AI technology analyzes millions of market data points to deliver profitable trading opportunities directly to you.
        </p>
      </div>
      
      {/* Process Steps */}
      <div className="grid md:grid-cols-3 gap-8 mb-16">
        {/* Market Scanning */}
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20">
          <div className="text-blue-500 text-4xl font-bold mb-4">01</div>
          <h3 className="text-xl font-bold text-white mb-4">Market Scanning</h3>
          <p className="text-gray-400 mb-4">
            Our AI continuously monitors the FIFA market, analyzing price trends, supply & demand, and market inefficiencies.
          </p>
          <div className="bg-black/60 h-48 rounded-lg mb-4" />
        </div>

        {/* AI Analysis */}
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-purple-500/20">
          <div className="text-purple-500 text-4xl font-bold mb-4">02</div>
          <h3 className="text-xl font-bold text-white mb-4">AI Analysis</h3>
          <p className="text-gray-400 mb-4">
            Advanced algorithms process the data to identify high-probability trading opportunities with minimal risk.
          </p>
          <img 
            src={aiAnalysis} 
            alt="AI Analysis Interface" 
            className="h-48 w-full rounded-lg mb-4 object-cover"
          />
        </div>

        {/* Discord Alerts */}
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-green-500/20">
          <div className="text-green-500 text-4xl font-bold mb-4">03</div>
          <h3 className="text-xl font-bold text-white mb-4">Discord Alerts</h3>
          <p className="text-gray-400 mb-4">
            Receive instant notifications in our Discord channel when profitable opportunities are identified.
          </p>
          <img 
            src={discordAlerts} 
            alt="Discord Alerts" 
            className="h-48 w-full rounded-lg mb-4 object-cover"
          />
        </div>
      </div>

      {/* Results Section */}
      <div className="bg-black/40 backdrop-blur-sm rounded-lg p-8 mb-16">
        <h2 className="text-3xl font-bold text-white mb-8 text-center">Proven Results</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {/* Profit Screenshots */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-white mb-4">Recent Profits</h3>
            <div className="grid grid-cols-2 gap-4">
              <img src={profit1} alt="Profit Example 1" className="h-32 w-full rounded-lg object-cover" />
              <img src={profit2} alt="Profit Example 2" className="h-32 w-full rounded-lg object-cover" />
              <img src={profit3} alt="Profit Example 3" className="h-32 w-full rounded-lg object-cover" />
              <div className="bg-black/60 h-32 rounded-lg" /> {/* Placeholder för fjärde bilden */}
            </div>
          </div>

          {/* Stats */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-white mb-4">Performance Stats</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-black/60 p-4 rounded-lg">
                <div className="text-2xl font-bold text-green-500">94.6%</div>
                <div className="text-gray-400">Success Rate</div>
              </div>
              <div className="bg-black/60 p-4 rounded-lg">
                <div className="text-2xl font-bold text-blue-500">2.4M+</div>
                <div className="text-gray-400">Coins Profit per day</div>
              </div>
              <div className="bg-black/60 p-4 rounded-lg">
                <div className="text-2xl font-bold text-purple-500">24/7</div>
                <div className="text-gray-400">Market Scanning</div>
              </div>
              <div className="bg-black/60 p-4 rounded-lg">
                <div className="text-2xl font-bold text-yellow-500">5min</div>
                <div className="text-gray-400">Avg. Response Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="text-center">
        <h2 className="text-3xl font-bold text-white mb-6">Ready to Start Trading?</h2>
        <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
          Join our community of successful traders and get access to our AI-powered trading signals today.
        </p>
        <a 
          href="https://patreon.com/aifuttrader"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg text-lg font-semibold transition-colors"
        >
          Get Started Now
        </a>
      </div>
    </div>
  );
}

export default HowItWorks; 