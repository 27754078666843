import { useState } from 'react';

function Register() {
    // Lägg till state för formulärdata och laddning/fel
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        discord: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Hantera input-ändringar
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    // Hantera formulär-submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Validera lösenord
            if (formData.password !== formData.confirmPassword) {
                throw new Error('Lösenorden matchar inte');
            }

            const response = await fetch('https://aifuttrader.com/api/register.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    username: formData.username,
                    email: formData.email,
                    password: formData.password,
                    discord: formData.discord
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Registration failed');
            }

            // Visa success meddelande på engelska
            setSuccess('Registration successful! Redirecting to dashboard...');
            
            // Vänta 2 sekunder innan redirect
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 2000);

        } catch (err) {
            setError(err.message);
            console.error('Registration error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-black pt-16 flex items-center justify-center">
            <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
                <h2 className="text-2xl font-bold text-white mb-6 text-center">Create Account</h2>
                
                {error && (
                    <div className="mb-4 p-3 bg-red-500 text-white rounded">
                        {error}
                    </div>
                )}
                
                {success && (
                    <div className="mb-4 p-3 bg-green-500 text-white rounded">
                        {success}
                    </div>
                )}
                
                <form className="space-y-4" onSubmit={handleSubmit}>
                    {/* Username */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="username">Username</label>
                        <input 
                            type="text" 
                            id="username"
                            value={formData.username}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your username"
                            required
                        />
                    </div>

                    {/* Email */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    
                    {/* Password */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your password"
                            required
                        />
                    </div>

                    {/* Confirm Password */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="confirmPassword">Confirm Password</label>
                        <input 
                            type="password" 
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Confirm your password"
                            required
                        />
                    </div>

                    {/* Discord Username */}
                    <div>
                        <label className="block text-gray-300 mb-2" htmlFor="discord">Discord Username</label>
                        <input 
                            type="text" 
                            id="discord"
                            value={formData.discord}
                            onChange={handleChange}
                            className="w-full bg-gray-800 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your Discord username"
                        />
                    </div>
                    
                    {/* Register Button */}
                    <button 
                        type="submit"
                        disabled={loading}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors disabled:opacity-50"
                    >
                        {loading ? 'Creating Account...' : 'Create Account'}
                    </button>
                </form>
                
                {/* Login Link */}
                <div className="mt-6 text-center text-gray-400">
                    Already have an account?{' '}
                    <a href="/login" className="text-blue-500 hover:text-blue-400">
                        Login here
                    </a>
                </div>

                {/* Terms */}
                <p className="mt-4 text-center text-sm text-gray-500">
                    By registering, you agree to our{' '}
                    <a href="/terms" className="text-blue-500 hover:text-blue-400">
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href="/privacy" className="text-blue-500 hover:text-blue-400">
                        Privacy Policy
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Register;