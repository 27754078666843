import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrera Chart.js komponenter
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PriceChart({ priceData }) {
  const [timeFilter, setTimeFilter] = useState('1h');
  
  if (!priceData?.pricelist || priceData.pricelist.length === 0) {
    return <div className="text-white text-center">No price history available</div>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: { color: 'white' }
      },
      title: {
        display: true,
        text: 'Price History',
        color: 'white'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Price: ${parseInt(context.raw).toLocaleString()} coins`;
          }
        }
      }
    },
    scales: {
      y: {
        ticks: { 
          color: 'white',
          callback: (value) => `${value.toLocaleString()} coins`
        },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      },
      x: {
        ticks: { 
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      }
    }
  };

  // Filtrera data baserat på timeFilter
  const filterData = () => {
    const now = new Date();
    const filtered = priceData.pricelist.filter(item => {
      const itemTime = new Date(item.time_expires);
      const hoursDiff = (now - itemTime) / (1000 * 60 * 60);
      return timeFilter === '1h' ? hoursDiff <= 1 : hoursDiff <= 24;
    });
    
    return {
      labels: filtered.map(item => item.time_expires),
      datasets: [{
        label: 'Price History',
        data: filtered.map(item => {
          const price = item.e_bid_price_clean || item.e_bid_price || '0';
          return parseInt(price.replace(/,/g, '')) || 0;
        }),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.4
      }]
    };
  };

  return (
    <div>
      <div className="flex gap-4 mb-4">
        <button 
          className={`px-4 py-2 rounded ${timeFilter === '1h' ? 'bg-blue-500' : 'bg-gray-700'} text-white`}
          onClick={() => setTimeFilter('1h')}
        >
          1h
        </button>
        <button 
          className={`px-4 py-2 rounded ${timeFilter === '24h' ? 'bg-blue-500' : 'bg-gray-700'} text-white`}
          onClick={() => setTimeFilter('24h')}
        >
          24h
        </button>
      </div>
      
      <Line options={options} data={filterData()} />
    </div>
  );
}

function PlayerDetails() {
  const { id } = useParams();
  const [player, setPlayer] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          throw new Error('Inget spelare-ID angivet');
        }

        const playerId = id.split('-').pop();
        console.log('Fetching data for player ID:', playerId);
        
        const priceResponse = await axios.get(`http://localhost:5001/api/player-price/${playerId}`);
        console.log('Price response:', priceResponse.data);

        if (priceResponse.data) {
          setPriceData(priceResponse.data);
          setPlayer(priceResponse.data.player);
        } else {
          throw new Error('Ingen prisdata hittades');
        }

        setLoading(false);
      } catch (err) {
        console.error('Detailed error:', err);
        setError(
          err.response?.data?.error || 
          err.message || 
          'Kunde inte hämta data. Kontrollera att backend-servern är igång.'
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

    // Debugging-utskrift
    useEffect(() => {
      console.log('Current state:', { player, priceData, loading, error });
    }, [player, priceData, loading, error]);

    if (loading) return (
      <div className="flex justify-center items-center min-h-screen bg-[#1a1b2e]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );

    if (error) return (
      <div className="flex justify-center items-center min-h-screen bg-[#1a1b2e]">
        <div className="text-white text-center bg-[#2A2B3F] p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-2">Ett fel uppstod</h2>
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-4 py-2 bg-blue-500 rounded hover:bg-blue-600"
          >
            Försök igen
          </button>
        </div>
      </div>
    );

    if (!player || !priceData) return (
      <div className="text-white text-center">Ingen data hittades</div>
    );

    return (
      <div className="min-h-screen bg-[#1a1b2e] py-8 px-4">
        <div className="max-w-7xl mx-auto space-y-6">
          <PlayerHeader player={player} priceData={priceData} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <KeyStats player={player} />
            <DetailedStats player={player} />
          </div>
          {player.alternatePositions?.length > 0 && (
            <AlternatePositions positions={player.alternatePositions} />
          )}
          <PlayerAbilities abilities={player.playerAbilities} />
          <AdditionalInfo player={player} />
          {priceData && <PriceHistory priceData={priceData} />}
        </div>
      </div>
    );
  }

  // Separata komponenter för varje sektion
  const PlayerHeader = ({ player, priceData }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare header-sektion */}
    </div>
  );

  const KeyStats = ({ player }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare key-stats-sektion */}
    </div>
  );

  const DetailedStats = ({ player }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare detailed-stats-sektion */}
    </div>
  );

  const AlternatePositions = ({ positions }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare alternate-positions-sektion */}
    </div>
  );

  const PlayerAbilities = ({ abilities }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare player-abilities-sektion */}
    </div>
  );

  const AdditionalInfo = ({ player }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      {/* Innehåll från tidigare additional-info-sektion */}
    </div>
  );

  const PriceHistory = ({ priceData }) => (
    <div className="bg-[#2A2B3F] rounded-lg p-6">
      <h3 className="text-xl font-bold text-white mb-4">Price History</h3>
      <div className="h-[400px]">
        <PriceChart priceData={priceData} />
      </div>
    </div>
  );

  export default PlayerDetails;
