import { useState, useEffect } from 'react';

// Lägg till detta direkt efter imports, före function Demo()
const twitterTimelineStyles = `
  .twitter-timeline-wrapper {
    min-height: 600px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .twitter-timeline {
    background-color: transparent !important;
  }
`;

// Uppdatera twitterTimelineStyles till socialMediaStyles
const socialMediaStyles = `
  .instagram-wrapper {
    min-height: 750px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
  }

  .instagram-wrapper iframe {
    position: relative;
    z-index: 2;
  }
`;

function Demo() {
  const [activeTab, setActiveTab] = useState('lazy-list');
  const [coins, setCoins] = useState('');
  const [platform, setPlatform] = useState(''); // Ny state för plattform
  const [strategy, setStrategy] = useState(null);
  // Lägg till state för players
  const [players, setPlayers] = useState({ headers: [], items: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const playersPerPage = 15;
  // Lägg till ny state för mass bid data
  const [massBidData, setMassBidData] = useState({ massBidItems: [] });

  const menuItems = [
    { id: 'lazy-list', title: 'Lazy List for Profit', icon: '📈' },
    { id: 'mass-bid', title: 'Mass Bid Strategy', icon: '🎯' },
    { id: 'trading-strategy', title: 'Trading Strategy', icon: '💰' },
    { id: 'price-fixing', title: 'Price Fixing Detection', icon: '🔍' },
    { id: 'auto-snipe', title: 'Auto Snipe System', icon: '⚡' },
    { id: 'market-analysis', title: 'Market Analysis', icon: '📊' },
    { id: 'extensions', title: 'Extensions', icon: '🔌' }, // Ny rad
    { id: 'leaks', title: 'FUT Leaks', icon: '🔥' },
  ];

  // Lägg till useEffect för att hämta spelardata
  useEffect(() => {
    if (activeTab === 'lazy-list') {
      const fetchPlayers = async () => {
        try {
          // Hämta den senaste JSON-filen från servern
          const response = await fetch('/static/data/futgg_players_latest.json');
          const data = await response.json();
          setPlayers(data);
        } catch (error) {
          console.error('Error fetching players:', error);
          // Fallback till localStorage om serverfetch misslyckas
          const localData = localStorage.getItem('demo_players');
          if (localData) {
            setPlayers(JSON.parse(localData));
          }
        }
      };

      fetchPlayers();

      // Lyssna efter uppdateringar från Admin
      const handleUpdate = () => {
        const localData = localStorage.getItem('demo_players');
        if (localData) {
          setPlayers(JSON.parse(localData));
        }
      };

      window.addEventListener('playersUpdated', handleUpdate);

      return () => {
        window.removeEventListener('playersUpdated', handleUpdate);
      };
    }
  }, [activeTab]);

  // Lägg till detta direkt efter alla useState hooks
  useEffect(() => {
    // Skapa style element
    const styleSheet = document.createElement("style");
    styleSheet.innerText = twitterTimelineStyles;
    document.head.appendChild(styleSheet);

    // Cleanup
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Lägg till denna useEffect för Twitter widget
  useEffect(() => {
    if (activeTab === 'leaks' && window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load();
    }
  }, [activeTab]);

  // Uppdatera useEffect för att hämta mass bid data
  useEffect(() => {
    if (activeTab === 'mass-bid') {
      const fetchMassBidData = async () => {
        try {
          const response = await fetch('/static/data/mass_bid_latest.json');
          const data = await response.json();
          setMassBidData(data);
        } catch (error) {
          console.error('Error fetching mass bid data:', error);
        }
      };

      fetchMassBidData();

      // Lyssna efter uppdateringar från Admin
      const handleUpdate = () => {
        fetchMassBidData();
      };

      window.addEventListener('massBidUpdated', handleUpdate);

      return () => {
        window.removeEventListener('massBidUpdated', handleUpdate);
      };
    }
  }, [activeTab]);

  const getStrategyForCoins = (amount, platform) => {
    const coinAmount = parseInt(amount.replace(/[^0-9]/g, ''));
    
    if (coinAmount < 10000) {
      return {
        title: "Bronze Pack Method (BPM)",
        description: "Perfect for building initial capital. Buy bronze packs and sell all contents.",
        steps: [
          "Buy 750 coins bronze packs",
          "List all players and valuable consumables",
          "Quick sell remaining items",
          "Repeat process"
        ],
        expectedProfit: "1-2k coins per pack over time"
      };
    } else if (coinAmount < 50000) {
      return {
        title: "Silver Flipping",
        description: "Focus on popular silver cards from major leagues.",
        steps: [
          "Search for silver cards from top 5 leagues",
          "Buy cards listed 20-30% below market value",
          "Relist with 500-1000 coins profit margin",
          "Focus on cards with high demand"
        ],
        expectedProfit: "500-1000 coins per card"
      };
    } else if (coinAmount < 200000) {
      return {
        title: "Gold Rare Mass Bidding",
        description: "Mass bid on popular gold rare cards during off-peak hours.",
        steps: [
          "Target 83-85 rated meta cards",
          "Place mass bids 10-15% below market value",
          "Sell during peak hours",
          "Focus on cards used in popular formations"
        ],
        expectedProfit: "2-5k coins per card"
      };
    } else {
      return {
        title: "High-Value Special Card Trading",
        description: "Focus on special cards and high-rated meta players.",
        steps: [
          "Monitor special card prices during rewards days",
          "Buy cards when market is low",
          "Hold cards for market recovery",
          "Sell during peak demand periods"
        ],
        expectedProfit: "10-50k coins per card"
      };
    }
  };

  // Beräkna rekommenderade priser
  const calculatePrices = (marketPrice) => {
    const buyPrice = Math.floor(marketPrice * 0.93); // 7% under marknadspris
    const sellPrice = Math.ceil(marketPrice * 1.05); // 5% över marknadspris
    const afterTax = Math.floor(sellPrice * 0.95); // 5% EA Tax
    const potentialProfit = afterTax - buyPrice;
    return { buyPrice, sellPrice, potentialProfit };
  };

  // Uppdatera renderLazyList för bättre mobilvy
  const renderLazyList = () => {
    const items = Array.isArray(players.items) ? players.items : [];
    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = items.slice(indexOfFirstPlayer, indexOfLastPlayer);
    const totalPages = Math.ceil(items.length / playersPerPage);

    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Lazy List for Profit</h3>
        
        <div className="bg-black/40 backdrop-blur-sm p-2 md:p-6 rounded-lg border border-blue-500/20">
          <div className="mb-4 text-gray-300 text-sm md:text-base px-2">
            All <span className="text-yellow-500">potential profits</span> are calculated <span className="text-red-500">after 5% EA tax</span>
          </div>

          {/* Scrollable container för mobil */}
          <div className="overflow-x-auto">
            <table className="w-full min-w-[640px]"> {/* Min-width för att undvika för smal tabell */}
              <thead>
                <tr>
                  <th className="p-2 text-center text-gray-300 text-sm md:text-base">Rating</th>
                  <th className="p-2 text-left text-gray-300 text-sm md:text-base">Name</th>
                  <th className="p-2 text-right text-gray-300 text-sm md:text-base">Price</th>
                  <th className="p-2 text-right text-gray-300 text-sm md:text-base">Buy</th>
                  <th className="p-2 text-right text-gray-300 text-sm md:text-base">Sell</th>
                  <th className="p-2 text-right text-gray-300 text-sm md:text-base">Profit</th>
                  <th className="p-2 text-center text-gray-300 text-sm md:text-base">Link</th>
                </tr>
              </thead>
              <tbody>
                {currentPlayers.map((player, index) => {
                  const marketPrice = parseInt(player.columns[2].value.replace(/,/g, ''));
                  const { buyPrice, sellPrice, potentialProfit } = calculatePrices(marketPrice);
                  
                  return (
                    <tr key={index} className="border-t border-gray-800">
                      <td className="p-2 text-center text-white">{player.rating}</td>
                      <td className="p-2 text-left text-white">{player.name}</td>
                      <td className="p-2 text-right text-white">{marketPrice.toLocaleString()}</td>
                      <td className="p-2 text-right text-green-500">{buyPrice.toLocaleString()}</td>
                      <td className="p-2 text-right text-blue-500">{sellPrice.toLocaleString()}</td>
                      <td className="p-2 text-right text-yellow-500">+{potentialProfit.toLocaleString()}</td>
                      <td className="p-2 text-center">
                        <a 
                          href={player.link}
                          className="text-blue-500 hover:text-blue-400"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Details
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Trading Tips */}
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-green-500/20">
          <h4 className="text-xl font-bold text-white mb-4">Trading Tips</h4>
          <div className="mb-4 p-3 bg-yellow-500/10 border border-yellow-500/20 rounded">
            <span className="text-yellow-500 font-semibold">Important:</span>
            <span className="text-gray-300"> All profit calculations include the 5% EA tax deduction</span>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-300">
            <li>Try to snipe players for <span className="text-green-500">7% below</span> current market price</li>
            <li>List players for <span className="text-blue-500">5% above</span> current market price</li>
            <li>Remember that EA takes <span className="text-red-500">5% tax</span> on all sales</li>
            <li>Best trading hours: 00:00 - 03:00 GMT (less competition)</li>
            <li>Check player prices before listing to ensure maximum profit</li>
          </ul>
        </div>
      </div>
    );
  };

  const renderLeaks = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">FUT Leaks</h3>
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20">
          <div className="instagram-wrapper">
            <iframe
              src="https://www.instagram.com/thefutsheriff/embed"
              width="100%"
              height="750"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderMassBid = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Mass Bid Strategy</h3>
        
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20">
          <div className="mb-4 text-gray-300">
            Target these players for mass bidding. Recommended bid values are calculated based on current market trends.
          </div>

          <table className="w-full">
            <thead>
              <tr>
                <th className="p-2 text-center text-gray-300">Rating</th>
                <th className="p-2 text-left text-gray-300">Player</th>
                <th className="p-2 text-center text-gray-300">Position</th>
                <th className="p-2 text-center text-gray-300">Nation</th>
                <th className="p-2 text-center text-gray-300">League</th>
                <th className="p-2 text-center text-gray-300">Team</th>
                <th className="p-2 text-center text-gray-300">Rarity</th>
                <th className="p-2 text-right text-gray-300">Min Bid</th>
              </tr>
            </thead>
            <tbody>
              {massBidData.massBidItems.map((item, index) => (
                <tr 
                  key={index}
                  className="border-t border-gray-800 hover:bg-blue-500/10 transition-colors"
                >
                  <td className="p-2 text-center text-white">{item.rating}</td>
                  <td className="p-2 text-left text-white">{item.player}</td>
                  <td className="p-2 text-center text-gray-300">{item.position}</td>
                  <td className="p-2 text-center text-gray-300">{item.nation}</td>
                  <td className="p-2 text-center text-gray-300">{item.league}</td>
                  <td className="p-2 text-center text-gray-300">{item.team}</td>
                  <td className="p-2 text-center text-gray-300">{item.rarity}</td>
                  <td className="p-2 text-right text-green-500">{item.minBid.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderExtensions = () => {
    const extensions = [
      {
        id: 'sniping-bot',
        title: 'AI Sniping Bot',
        status: 'Coming Soon',
        description: 'Advanced sniping bot powered by machine learning algorithms. Automatically finds and snipes underpriced players with millisecond precision.',
        features: [
          'Smart price analysis',
          'Auto-buy functionality',
          'Custom filter settings',
          'Market pattern learning',
          'Anti-detection system'
        ],
        progress: 75,
        expectedRelease: 'Q4 2024', // Uppdaterad till Q4 2024
        icon: '🤖'
      },
      {
        id: 'market-analyzer',
        title: 'Market Analyzer Pro',
        status: 'In Development',
        description: 'Real-time market analysis tool that tracks price trends and identifies profitable trading opportunities.',
        features: [
          'Live price tracking',
          'Profit margin calculator',
          'Market trend visualization',
          'Price prediction models',
          'Custom alerts system'
        ],
        progress: 45,
        expectedRelease: 'Q4 2024', // Uppdaterad till Q4 2024
        icon: '📊'
      }
    ];

    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Extensions & Tools</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {extensions.map(ext => (
            <div key={ext.id} className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-colors">
              {/* Header */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-3">
                  <span className="text-2xl">{ext.icon}</span>
                  <h4 className="text-xl font-bold text-white">{ext.title}</h4>
                </div>
                <span className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm">
                  {ext.status}
                </span>
              </div>

              {/* Description */}
              <p className="text-gray-300 mb-4">
                {ext.description}
              </p>

              {/* Progress Bar */}
              <div className="mb-4">
                <div className="flex justify-between text-sm text-gray-400 mb-1">
                  <span>Development Progress</span>
                  <span>{ext.progress}%</span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-2">
                  <div 
                    className="bg-blue-500 rounded-full h-2 transition-all duration-500"
                    style={{ width: `${ext.progress}%` }}
                  ></div>
                </div>
              </div>

              {/* Features */}
              <div className="mb-4">
                <h5 className="text-blue-400 mb-2">Key Features:</h5>
                <ul className="space-y-2">
                  {ext.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-300">
                      <span className="text-green-500 mr-2">✓</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Release Date */}
              <div className="mt-4 pt-4 border-t border-gray-700">
                <span className="text-gray-400">Expected Release: </span>
                <span className="text-white">{ext.expectedRelease}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Lägg till renderTradingStrategy funktion
  const renderTradingStrategy = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-2xl font-bold text-white">Trading Strategy</h3>
        
        <div className="bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20">
          <div className="space-y-6">
            <div className="flex items-center space-x-4 mb-6">
              <span className="text-3xl">💰</span>
              <h4 className="text-xl font-semibold text-white">Current Market Strategy</h4>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Strategy Card 1 */}
              <div className="bg-gray-800/50 p-4 rounded-lg">
                <h5 className="text-blue-400 font-semibold mb-3">Icon Trading</h5>
                <p className="text-gray-300">Focus on Icon trading during off-peak hours. Buy during squad battles rewards, sell during peak hours.</p>
              </div>

              {/* Strategy Card 2 */}
              <div className="bg-gray-800/50 p-4 rounded-lg">
                <h5 className="text-blue-400 font-semibold mb-3">Special Cards</h5>
                <p className="text-gray-300">Target special cards from current and previous promos. Look for cards with unique attributes.</p>
              </div>

              {/* Strategy Card 3 */}
              <div className="bg-gray-800/50 p-4 rounded-lg">
                <h5 className="text-blue-400 font-semibold mb-3">Fodder Investment</h5>
                <p className="text-gray-300">Stock up on 83-86 rated cards when supply is high. Sell during popular SBC releases.</p>
              </div>

              {/* Strategy Card 4 */}
              <div className="bg-gray-800/50 p-4 rounded-lg">
                <h5 className="text-blue-400 font-semibold mb-3">Meta Players</h5>
                <p className="text-gray-300">Monitor meta players during Weekend League rewards. Buy during panic sells.</p>
              </div>
            </div>

            {/* Market Tips */}
            <div className="mt-8">
              <h5 className="text-xl font-semibold text-white mb-4">Current Market Tips</h5>
              <ul className="space-y-3">
                <li className="flex items-center text-gray-300">
                  <span className="text-green-500 mr-3">✓</span>
                  Buy during lightning rounds and sell during content drops
                </li>
                <li className="flex items-center text-gray-300">
                  <span className="text-green-500 mr-3">✓</span>
                  Focus on cards that link to popular SBC players
                </li>
                <li className="flex items-center text-gray-300">
                  <span className="text-green-500 mr-3">✓</span>
                  Invest in popular league players before Weekend League
                </li>
                <li className="flex items-center text-gray-300">
                  <span className="text-green-500 mr-3">✓</span>
                  Monitor social media for potential market-impacting leaks
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'lazy-list':
        return renderLazyList();
      case 'mass-bid':
        return renderMassBid();
      case 'leaks':
        return renderLeaks();
      case 'extensions':
        return renderExtensions();
      case 'trading-strategy':
        return renderTradingStrategy();
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Top Stats Bar */}
      <div className="bg-black/40 backdrop-blur-sm border-b border-gray-800">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            {/* Stats */}
            <div className="grid grid-cols-3 gap-4 flex-1">
              <div className="text-center">
                <p className="text-green-500">Daily Profit</p>
                <p className="text-2xl text-white">+2.4M</p>
              </div>
              <div className="text-center">
                <p className="text-blue-500">Active Signals</p>
                <p className="text-2xl text-white">14</p>
              </div>
              <div className="text-center">
                <p className="text-purple-500">Success Rate</p>
                <p className="text-2xl text-white">94.6%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar */}
          <div className="md:w-64 space-y-2">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`w-full text-left px-4 py-3 rounded transition-colors flex items-center space-x-3
                  ${activeTab === item.id 
                    ? 'bg-blue-500 text-white' 
                    : 'text-gray-400 hover:bg-blue-500/10 hover:text-white'}`}
              >
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            ))}
          </div>

          {/* Content Area */}
          <div className="flex-1 min-h-[600px] bg-black/40 backdrop-blur-sm rounded-lg p-6">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo;
