import Navbar from './Navbar';
import Footer from './Footer';

function Terms() {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-black text-white pt-24 pb-16">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
          
          <div className="space-y-8 text-gray-300">
            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">1. Acceptance of Terms</h2>
              <p>By accessing and using AIFutTrader, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">2. Use License</h2>
              <p>AIFutTrader grants you a personal, non-transferable, non-exclusive license to use our software for FIFA trading purposes. You may not:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Modify or copy the materials</li>
                <li>Use the materials for commercial purposes</li>
                <li>Transfer the materials to another person</li>
                <li>Attempt to decompile or reverse engineer the software</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">3. Disclaimer</h2>
              <p>Our services are provided "as is". AIFutTrader makes no warranties about the accuracy or reliability of the software's trading predictions.</p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;