import React from 'react';
import { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

function Hero() {
  const [stats, setStats] = useState({
    profit: 0,
    signals: 0,
    successRate: 0
  });

  const [reviewCount, setReviewCount] = useState(156);

  // Lägg till detta i början av komponenten tillsammans med stats state
  const liveMessages = [
    { player: "█████", prediction: "price expected to rise in the next hour" },
    { player: "████████", prediction: "showing strong buy signals" },
    { player: "███████", prediction: "potential crash incoming - sell now" },
    { player: "████", prediction: "mass listing detected, snipe opportunity" },
    { player: "██████", prediction: "price peak detected, optimal sell time" }
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    updateStats();
    const interval = setInterval(() => {
      updateStats();
    }, 5 * 60 * 1000);

    // Lägg till denna useEffect tillsammans med stats useEffect
    const messageInterval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % liveMessages.length);
    }, 5000);

    return () => {
      clearInterval(interval);
      clearInterval(messageInterval);
    };
  }, []);

  const updateStats = () => {
    const baseProfit = 2.4;
    const randomProfit = baseProfit + (Math.random() * 0.4 - 0.2);
    const baseSignals = 14;
    const randomSignals = Math.floor(baseSignals + (Math.random() * 6 - 3));
    const baseSuccessRate = 94.6;
    const randomSuccessRate = (baseSuccessRate + (Math.random() * 2 - 1)).toFixed(1);

    setStats({
      profit: randomProfit,
      signals: randomSignals,
      successRate: randomSuccessRate
    });
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center text-center px-4 bg-cover bg-center bg-no-repeat" 
         style={{ 
           backgroundImage: 'url("/bg.png")', // Notera att sökvägen börjar från public-mappen
           backgroundSize: 'cover',
           backgroundPosition: 'center',
         }}>
      <div className="absolute inset-0 bg-black/70"></div> {/* Ökad opacitet för mörkare overlay */}
      
      {/* Content wrapper - lägg till pt-20 för mobil */}
      <div className="relative z-10 flex flex-col items-center justify-center w-full pt-20 md:pt-0">
        <h1 className="text-3xl md:text-5xl font-bold text-white mb-6 max-w-4xl mx-auto leading-tight">
        The Most Sophisticated AI Trading Model 
        for EA FC 25

        </h1>

        {/* Review Banner */}
        <div className="bg-emerald-500 rounded-full py-2 px-6 mb-8 inline-flex items-center space-x-2">
          <span className="text-white font-semibold">Excellent</span>
          <div className="flex space-x-1">
            {[...Array(5)].map((_, i) => (
              <svg key={i} className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>
          <span className="text-white">4.8 out of 5 based on {reviewCount} reviews</span>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-4 mb-12">
          <a 
            href="https://www.patreon.com/c/aifuttrader/membership" 
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-lg text-lg font-semibold transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get AI Bot Access
          </a>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl w-full">
          {/* Today's Profit */}
          <div className="relative bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-green-500/20">
            <div className="absolute top-2 right-2">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
                <span className="text-xs text-green-500">LIVE</span>
              </div>
            </div>
            <h3 className="text-green-500 text-lg mb-2">TODAY'S PROFIT</h3>
            <div className="flex items-center justify-center">
              <span className="text-white text-4xl font-bold">+</span>
              <CountUp
                end={stats.profit}
                decimals={1}
                duration={2}
                separator=","
                suffix="M"
                className="text-white text-4xl font-bold"
              />
            </div>
            <p className="text-green-500/80 mt-2">Coins Generated</p>
          </div>

          {/* Live Predictions */}
          <div className="relative bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-blue-500/20">
            <div className="absolute top-2 right-2">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-blue-500 rounded-full mr-2 animate-pulse"></div>
                <span className="text-xs text-blue-500">LIVE</span>
              </div>
            </div>
            <h3 className="text-blue-500 text-lg mb-2">LIVE PREDICTIONS</h3>
            <CountUp
              end={stats.signals}
              duration={2}
              className="text-white text-4xl font-bold"
            />
            <p className="text-blue-500/80 mt-2">Active Signals</p>
          </div>

          {/* Success Rate */}
          <div className="relative bg-black/40 backdrop-blur-sm p-6 rounded-lg border border-purple-500/20">
            <div className="absolute top-2 right-2">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-purple-500 rounded-full mr-2 animate-pulse"></div>
                <span className="text-xs text-purple-500">LIVE</span>
              </div>
            </div>
            <h3 className="text-purple-500 text-lg mb-2">SUCCESS RATE</h3>
            <CountUp
              end={stats.successRate}
              decimals={1}
              duration={2}
              suffix="%"
              className="text-white text-4xl font-bold"
            />
            <p className="text-purple-500/80 mt-2">Last 24 Hours</p>
          </div>
        </div>

        {/* Live Alert - Uppdaterad version */}
        <div className="mt-8 bg-black/60 backdrop-blur-sm p-4 rounded-lg max-w-2xl w-full">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></div>
            <span className="text-green-500">Live:</span>
            <span className="text-white">AI just identified a new trading opportunity -</span>
            <span className="text-gray-400 bg-gray-700/50 px-1 rounded cursor-not-allowed">
              {liveMessages[currentMessageIndex].player}
            </span>
            <span className="text-gray-400">
              {liveMessages[currentMessageIndex].prediction}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
